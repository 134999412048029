<template>
  <CommonTooltip :tooltip="$t('cars-list.bid-submitted')">
    <DynamicAuctionItemListCarIcon
      variant="orange"
      size="small"
      class="cursor-default"
      v-bind="$attrs"
    >
      <i class="ab-icon ab-icon-hammer h-full"></i>
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>
</template>
